<template>
    <span>{{ date }}</span>
</template>

<script>
import { computed } from 'vue';
import { DateTime } from 'luxon';

export default {
    name: 'DateColumn',
    props: {
        value: { type: [String, null], required: true },
    },
    setup(props) {
        const date = computed(() => {
            const d = DateTime.fromISO(props.value);
            if (d.isValid) {
                return d.toFormat('yyyy-MM-dd');
            }
            return '-';
        });

        return { date };
    },
};
</script>

<style></style>
